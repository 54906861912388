import './style.css';

export default function Team() {
  return (
    <div className="teamContainer">
      <h1>Our Team</h1>
      <p>
        Coming soon ...
      </p>
    </div>
  );
}
